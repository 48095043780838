<template>
  <div class="app-container">
    <el-card shadow="never" class="cardBg">
      <div>Order Number: {{ orderInfo.orderNumber }}</div>
      <div>Boost Code: {{orderInfo.boostCode}}</div>
      <div>Order Status: {{ orderInfo.orderStatus }}</div>
      <div>Ordered At: {{ orderInfo.orderedAt }}</div>
      <div>Purchase Type:{{ orderInfo.purchaseType }}</div>
      <div>Delivery Address: {{ orderInfo.deliveryInfo.addressDisplay }}</div>
      <div>
        Estimate Delivery Time:
        {{ this.date ? `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}` : ''}}
      </div>
      <div>Contact Name: {{ orderInfo.deliveryInfo.contactName }}</div>
      <div>Phone: {{ orderInfo.deliveryInfo.contactPhoneNumber }}</div>
      <div>Subtotal: {{ orderInfo.fees.subTotal.display }}</div>
      <div>Delivery Fee: {{ orderInfo.fees.deliveryFee.display }}</div>
      <div>Service Fee: {{ orderInfo.fees.serviceFee.display }}</div>
      <div>Tax: {{ orderInfo.fees.taxFee.display }}</div>
      <div>Discount: {{orderInfo.fees.discount.display}} </div>
      <div>Total: {{ orderInfo.fees.total.display }}</div>
    </el-card>
    <el-card shadow="never" class="cardBg">
      <div>Consumer Nickname: {{ orderInfo.consumer.nickname }}</div>
      <div>Consumer Email: {{ orderInfo.consumer.email }}</div>
      <div>Captain Nickname: {{ orderInfo.captainName }}</div>
      <div>Note: {{orderInfo.note}}</div>
    </el-card>
    <el-card shadow="never" class="cardBg" v-if="orderInfo.purchaseType == 'DIGITAL_BULKING'">
      <div>Flow Type: {{orderInfo.flowType}} </div>
      <div>Pickup Time: {{orderInfo.pickupTime}} </div>
    </el-card>
    <div class="table-container">
      <el-table
        ref="orderItems"
        :data="list"
        style="width: 100%"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Merchant Name" align="center">
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <el-table-column label="Product Name" align="center">
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="Product Attribute" align="center">
          <template slot-scope="scope">{{
            scope.row.productAttributeDescription
          }}</template>
        </el-table-column>
        <el-table-column label="Price" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.price.display }}</template>
        </el-table-column>
        <el-table-column label="Quantity" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.quantity }}</template>
        </el-table-column>
        <el-table-column label="Unit" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.unit }}</template>
        </el-table-column>
        <el-table-column label="Total Price" width="100" align="center">
          <template slot-scope="scope">{{
            scope.row.totalPrice.display
          }}</template>
        </el-table-column>
        <el-table-column label="SKU" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.skuCode }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-container">
      <el-table
        ref="orderStatusHistory"
        :data="orderStatusHistory"
        style="width: 100%"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Operator Type" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.operatorType }}</template>
        </el-table-column>
        <el-table-column label="Time" width="150" align="center">
          <template slot-scope="scope">{{ scope.row.time }}</template>
        </el-table-column>
        <el-table-column label="Operator Name" width="300" align="center">
          <template slot-scope="scope">{{ scope.row.operatorName }}</template>
        </el-table-column>
        <el-table-column label="Note" align="center">
          <template slot-scope="scope">{{ scope.row.note }}</template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getOrderDetail } from '@/api/order';

export default {
  name: 'orderList',
  data() {
    return {
      listLoading: true,
      list: null,
      orderInfo: null,
      date: null,
      orderStatusHistory: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      getOrderDetail(this.$route.query.id).then((response) => {
        this.list = response.data.order.lineItems;
        this.orderInfo = response.data.order;
        this.orderStatusHistory = response.data.orderStatusHistories;
        if (this.orderInfo.estimateDeliveryTime) this.date = new Date(this.orderInfo.estimateDeliveryTime);
        this.listLoading = false;
      });
    },
  },
  formatDate(val) {
    const date = new Date(val);
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
  },
};
</script>
